.courses-container {
  max-width: 100%;
  padding: 3rem 2rem;
}

.course-image {
  max-width: 100%;
  height: auto;
}

.register-interest-button {
  display: block;
  margin: 2rem auto;
  padding: 1rem;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.5rem;
  transition: background-color 0.3s ease;
}

.register-interest-button:hover {
  background-color: #d32f2f;
}
