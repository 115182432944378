body {
  margin: 0;
  background-color: #eeefe6;
  overflow-x: hidden;
}

h1 {
  font-family: "Gyahegi", sans-serif;
}

.whole-page {
  width: 100%;
  height: 100%;
  margin: 0;
}

.home-page {
  height: calc(100vh - 60px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #eeefe6;
}

.offset-text {
  z-index: 2;
  max-width: 800px;
  padding: 0 20px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  margin: 20px;
}

.tiles-section-container {
  width: 90%;
  position: relative;
  background-color: #eeefe6;
}

.offset-text h1 {
  font-family: "Gyahegi", sans-serif;
  font-weight: bold;
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

/* .offset-text h1 .the-text {
  font-size: 0.8em;
  vertical-align: top;
} */

.offset-text h1 div {
  display: inline-flex;
  align-items: baseline;
}

.offset-text h1 .boat-text {
  font-family: "July it", sans-serif;
  color: #e1b8ac;
  margin-left: 0.3em;
}

.offset-text h1 span {
  display: inline-block;
}

.offset-text p {
  font-size: 1.25rem;
  font-family: "Avenir", sans-serif;
  margin-top: 20px;
}

.arrow-down {
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  animation: bounce 2s infinite;
}

.second-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 10%;
  margin-top: 0;
}

.sub-heading {
  margin: 40px;
  padding: 10px;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  background-color: #f5f5f5;
}

.tile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  height: 100%;
  margin: 20px;
  background-color: #eeefe6;
}

.tile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #eeefe6;
}

.tile-img {
  display: flex;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
}

.tile-img img {
  height: 80%;
  width: auto;
  max-width: 100%;
  border-radius: 2%;
}

.tile-text {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tile-text p {
  text-align: left;
  font-size: 1.25rem;
  padding: 20px;
  font-family: "Avenir", sans-serif;
  width: 80%;
}

.reverse-tile {
  flex-direction: row-reverse;
}

.reverse-tile .tile-text {
  text-align: left;
}

@media (max-width: 768px) {
  .offset-text {
    max-width: 100%; /* Allow text to take full width on small screens */
    padding: 0 10px; /* Give a little bit of padding on the sides */
  }

  .offset-text h1 {
    font-size: 2rem; /* Reduce font size for small screens */
  }

  .tile {
    flex-direction: column;
    margin: 20px 0;
  }

  .tile-img,
  .tile-text {
    flex-basis: 100%;
  }

  .tile-text p {
    font-size: 1rem;
    text-align: center;
    width: 100%;
  }

  body {
    margin: 0;
    background-color: #eeefe6;
    overflow-x: hidden;
  }
  .sub-heading {
    margin-top: 150px;
    margin-bottom: 5px;
  }

  .second-page-container {
    margin: 0;
  }
}
