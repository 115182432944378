.admin-container {
  min-height: 80vh;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

.admin-heading {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.admin-panel {
  width: 100%;
  max-width: 45%;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 20px;
}

.admin-form,
.admin-section {
  margin-bottom: 20px;
}

.admin-form {
  display: flex;
  flex-direction: column;
}

.admin-label {
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 50px;
  text-align: center;
}

.admin-input {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 15px;
}

.admin-button {
  padding: 10px 20px;
  border-radius: 4px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.admin-button:hover {
  background-color: #0056b3;
}

.admin-subheading {
  font-size: 1.5em;
  color: #333;
}

.admin-panel h1 {
  text-align: center;
}
