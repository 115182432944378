.announcement-bar {
  background-color: #8d9e84;
  color: white;
  padding: 0.5rem 0;
  overflow: hidden;
  position: relative;
  font-weight: 600;
}

.announcement-bar__content {
  display: inline-block;
  white-space: nowrap;
  padding-left: 100%;
  animation: scrollingText 30s linear infinite;
}

@keyframes scrollingText {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
