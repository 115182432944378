.shop-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  padding: 20px;
  width: 90%;
  margin: 0 auto;
}

.intro-text {
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
  width: 70%;
  margin: 0 auto;
  padding: 20px;
}

.intro-text h1 {
  font-size: 2.5rem; /* Larger title */
  color: #333; /* Darker color for better contrast */
  margin-bottom: 20px;
}

.intro-text p {
  font-size: 1.3rem; /* Larger font for better readability */
  color: #666; /* Darker color for better contrast */
  padding-top: 20px;
  line-height: 1.6; /* Add line height for better readability */
}

.product-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px; /* Increase padding for better spacing */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* New property to separate content and push button to bottom */
  align-items: center;
  background-color: white;
  transition: all 0.3s ease; /* Transition for hover effect */
  height: 100%; /* Full height to allow button alignment */
  width: 288px;
  height: 380px;
}

.product-card:hover {
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1); /* Box shadow for hover effect */
  transform: translateY(-10px); /* Move up when hovered */
}

.product-image {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: center center;
  border-radius: 4px;
}

.product-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-name {
  font-size: 1.4rem; /* Larger font for better readability */
  color: #333; /* Darker color for better contrast */
  margin-top: 15px;
}

.more-info-btn {
  background-color: #8d9e84;
  color: white;
  padding: 10px 20px;
  margin-top: 20px;
  text-decoration: none;
  border-radius: 4px;
  align-self: stretch;
  text-align: center;
  transition: all 0.3s ease; /* Transition for hover effect */
}

.more-info-btn:hover {
  background-color: #7a896f; /* Darker color for hover effect */
}

@media (max-width: 768px) {
  .shop-page {
    grid-template-columns: 1fr;
  }

  .intro-text {
    width: 100%;
  }

  .intro-text h1 {
    font-size: 2rem; 
  }

  .intro-text p {
    font-size: 1.1rem; /* Smaller font for mobile */
    text-align: left;
  }

  .product-name {
    font-size: 1.2rem; /* Smaller font for mobile */
  }
}
