@font-face {
  font-family: "Gyahegi";
  src: url("/src/fonts/Gyahegi.otf") format("opentype");

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "July it";
  src: url("/src/fonts/July\ it.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
