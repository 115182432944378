* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Avenir, sans-serif;
  background-color: #eeefe6;
}

.WaltzingMatildaApp {
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 60px);
  width: 100%;
  padding-top: 65px;
  background-color: #eeefe6;
}

.WaltzingMatildaApp.top-secret-page {
  display: block;
  flex-direction: column;
  align-items: initial;
  justify-content: initial;
  padding-top: 0;
  background-color: black;
} /* @media (min-width: 768px) {
  .WaltzingMatildaApp {
    max-width: 1024px;
    margin: 0 auto;
  }
} */
