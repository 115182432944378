.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 1.5rem 2rem;
  color: #fff;
  background-color: #8d9e84;
  font-family: Arial, sans-serif;
  width: 100%;
}

.logo {
  flex: 1;
  max-width: 25%;
}

.logo h2 {
  font-family: "Gyahegi", sans-serif;
  font-weight: bold;
  color: #fff;
}

.footer-social {
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 25%;
}

.footer-social a {
  color: #fff;
  font-size: 32px;
  margin: 12px 20px;
}

.footer-social a:hover {
  color: #eeefe6;
}

.footer-columns {
  flex: 1;
  display: flex;
  justify-content: space-between;
  max-width: 50%;
}

.footer-columns .column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-columns .column h4 {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.footer-columns .column a {
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer-columns .column a:hover {
  color: #eeefe6;
}

.footer-columns .column button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  text-align: left;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-columns .column button:hover {
  color: #eeefe6;
}

.component-display {
  max-width: 100%;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
  margin-top: 1rem;
  padding: 1rem;
  color: #fff;
}

.component-display p {
  margin: 0;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
  }

  .logo,
  .footer-social,
  .footer-columns {
    max-width: 100%;
  }

  .footer-columns {
    flex-direction: column;
  }

  .footer-columns .column {
    margin-bottom: 1rem;
  }
}
