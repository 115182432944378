.page-divider-container {
  display: flex;
  justify-content: center;
  gap: 1em;
  margin: 5% 0;
}

.page-divider-item {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.page-divider-item:hover {
  transform: scale(1.05);
}

.page-divider-item.shop {
  background: url("../images/ooni.webp") center/cover no-repeat;
}

.page-divider-item.menu {
  background: url("../images/menu.webp") center/cover no-repeat;
}

.page-divider-item.socials {
  background: url("../images/socials.webp") center/cover no-repeat;
}

.page-divider-item.courses {
  background: url("../images/courses.webp") center/cover no-repeat;
}

.page-divider-item.map {
  background: url("../images/map.webp") center/cover no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider-btn {
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.divider-btn:hover {
  color: #000;
  background-color: #fff;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .page-divider-container {
    flex-direction: column;
    height: 100%;
    margin-bottom: 10%;
  }

  .page-divider-item {
    width: 100%;
    min-height: 200px;
    background-size: cover;
  }
}
