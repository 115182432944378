.map-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  width: 90%;
  margin: 2% auto;
  position: relative;
}

.leaflet-container {
  height: 75%;
  width: 60%;
  border-radius: 15px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
}

.location-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  font-family: "Avenir", sans-serif;
  color: #fff;
  background-color: #919f89;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  max-width: 60%;
  min-width: 60%;
}

.location-info p {
  margin: 15px 10px;
  text-align: center;
  font-size: 20px;
}

.location-info a {
  display: inline-block;
  margin: 0.5rem 0;
  border-radius: 5px;
  background-color: #333;
  padding: 0.5rem 1rem;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.2s ease;
  align-self: center;
  width: 50%;
  text-align: center;
}

.location-info a:hover {
  background-color: #4a804e;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  @media only screen and (max-width: 768px) {
    .map-container {
      height: 500px;
      width: 100%;
      margin-top: 10%;
    }

    .leaflet-container {
      height: 75%;
      width: 100%;
      border-radius: 15px;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15);
    }

    .location-info {
      max-width: 100%;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .location-info {
    flex-direction: column;
    align-items: center;
  }
}

.leaflet-marker-icon {
  color: #d6bfb2;
}

.leaflet-popup {
  font-family: "Avenir", sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
  color: #333;
}

.leaflet-popup-content {
  text-align: center;
}

/*

closed text
*/
.closed {
  margin-top: 20px;
  font-size: 1.2em;
  height: 35vh;
  text-align: center;
}
