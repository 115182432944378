.hidden {
  display: none;
}

.course-image-container {
  display: flex;
  justify-content: center;
  gap: 1em;
  margin: 5% 0;
}

.course-image-item {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.course-image-item.expanded {
  width: 80vw;
}

.course-image-item:hover {
  transform: scale(1.05);
}

.course-image-item.reviews {
  background: url("../images/course.webp") center/cover no-repeat;
}

.course-image-item.cover {
  background: url("../images/cover.webp") center/cover no-repeat;
}

.course-image-item.gallery {
  background: url("../images/gallery.webp") center/cover no-repeat;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider-btn {
  padding: 10px 20px;
  font-size: 1.2rem;
  color: #fff;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.divider-btn:hover {
  color: #000;
  background-color: #fff;
}

.expanded-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s ease;
  overflow: auto;
}

.expanded-content h2 {
  margin-bottom: 20px;
  font-size: 2.5em;
  text-align: center;
  width: 100%;
}

.expanded-content p,
.expanded-content ul {
  margin-bottom: 20px;
  text-align: justify;
  width: 100%;
}

.expanded-content ul {
  padding-left: 30px;
}

.expanded-content ul li {
  margin-bottom: 10px;
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.gallery-container img {
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  height: 250px;
  width: 250px;
}

.course-image-item.expanded .expanded-content {
  opacity: 1;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .course-image-container {
    flex-direction: column;
    height: 100%;
    margin-bottom: 10%;
  }

  .course-image-item {
    width: 100%;
    min-height: 200px;
    background-size: cover;
  }

  .course-image-item.expanded {
    min-height: 300px;
  }
}
