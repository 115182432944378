.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75vh;
}

.login-box {
  width: 300px;
  padding: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333333;
}

.login-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.login-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #ffffff;
  cursor: pointer;
}

.error-message {
  color: #ff0000;
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}
