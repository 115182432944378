.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.faq__title {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.faq__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  text-align: center;
}

.faq__question {
  font-weight: bold;
  font-size: 1.5rem;
}

.faq__answer {
  font-size: 1rem;
}
