.notice-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80vw;
  margin: auto;
}

.store-notice {
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 30px;
  width: fit-content;
  margin-bottom: 20px;
  margin-top: 20px;
}

.store-notice h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.store-notice p {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

a {
  text-decoration: none;
  color: black;
  text-align: center;
}
