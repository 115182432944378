.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.about-page__title,
.about-page__section-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.about-page__video-container {
  position: relative;
  width: 80%;
  padding-bottom: 45%;
  height: 0;
  margin: 0 auto;
  overflow: hidden;
}

.about-page__video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-video__placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.youtube-video__placeholder img {
  max-width: 100%;
  height: auto;
  border-radius: 2%;
}

.about-page__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  text-align: center;
}

.about-page__section-title {
  margin-bottom: 5px;
}

.team-members {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
}

.team-member {
  width: 30%;
  text-align: center;
  margin-bottom: 2rem;
}

.team-member img {
  width: 70%;
  height: 50%;
  border-radius: 50%;
  object-fit: cover;
}

.team-member h3,
.team-member h4 {
  margin: 0.5rem 0;
}

.testimonials {
  width: 80%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
}

@media (max-width: 768px) {
  .about-page {
    padding: 1rem;
  }

  .about-page__title,
  .about-page__section-title {
    font-size: 1.5rem;
    margin-bottom: 25px;
  }

  .about-page__section {
    display: block;
  }

  .about-page__video-container {
    width: 100%;
    padding-bottom: 56.25%;
  }

  .team-member {
    width: 80%;
    margin-bottom: 0;
  }

  .team-member img {
    width: 50%;
    height: 50%;
  }

  .testimonials {
    width: 100%;
  }

  .intro-text {
    width: 100%;
  }
}
