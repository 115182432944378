.contact-form {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 2% auto;
}

.contact-form__title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.contact-form__description {
  margin-bottom: 2rem;
  color: var(--dark-color);
}

.contact-form__group {
  margin-bottom: 1.5rem;
}

.contact-form__group label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--dark-color);
}

.contact-form__group input,
.contact-form__group textarea {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1rem;
}

.contact-form__submit {
  background-color: var(--primary-color);
  background-color: rgb(129, 129, 212);
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: auto;
  display: block;
}

.contact-form__submit:hover {
  background-color: var(--secondary-color);
}
