.false-navbar {
  height: var(--nav-height);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #8d9e84;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: var(--nav-height);
}

.logo {
  font-family: "Gyahegi", sans-serif;
  font-weight: bold;
  font-size: 0.6rem;
  color: #fff;
}

.logo h2 {
  display: inline-block;
  position: relative;
}

.logo span {
  font-family: "July it", sans-serif;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: -0.6em;
  margin-top: 1.3em;
  color: #e1b8ac;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  gap: 4px;
  cursor: pointer;
}

.hamburger-menu-line {
  width: 30px;
  height: 4px;
  background-color: #333;
}

.nav-links {
  display: flex;
  gap: 1rem;
  margin-right: 3rem;
}

.nav-links a {
  font-family: "Avenir", sans-serif;
  font-size: 1.1rem;
  color: #fff;
  text-decoration: none;
}

.nav-links a:hover,
.nav-links a.active {
  color: black;
}

/* Responsive styles */
@media (max-width: 768px) {
  .hamburger-menu {
    display: flex;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    gap: 1rem;
    position: fixed;
    top: 0;
    right: 2rem;
    bottom: 0;
    background-color: #fff;
    padding: 2rem;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    margin-right: 2rem;
  }

  .nav-links.open {
    display: flex;
    transform: translateX(0);
    background-color: #919f89;
    width: 40%;
    height: 50%;
  }

  .header.open {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}
